body {
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  font-size: 24px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: red blue;
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #35312b;
}
*::-webkit-scrollbar-thumb {
  background-color: #585653;
  border-radius: 10px;
}

button,
input {
  font-family: 'VT323', monospace;
  font-size: 24px;
}

:root {
  --pixel-size: 2;
  --grid-cell: calc( var(--pixel-size) * 32);
}

* {
  image-rendering: pixelated;
}